import { render, staticRenderFns } from "./CommunicationProtocol.vue?vue&type=template&id=8ee5fcae&scoped=true&"
import script from "./CommunicationProtocol.vue?vue&type=script&lang=js&"
export * from "./CommunicationProtocol.vue?vue&type=script&lang=js&"
import style0 from "./CommunicationProtocol.vue?vue&type=style&index=0&id=8ee5fcae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ee5fcae",
  null
  
)

export default component.exports